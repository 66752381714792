<template>
    <div>
        <div class="form-heading">
            <div class="form-heading-emoji-wrapper">
                <img src="/emoji/smiling.png" alt="smiling-img">
            </div>
            <div class="form-title mb-0">Setup Locations!</div>
        </div>
        <div>Choose the locations you'd like to get reporting for!</div>

        <select-locations-box
            :loading="locationsLoading"
            :locations="displayedLocations"
            v-model="locationsSearch"
            @select-location="selectLocation"
        />

        <b-button
            @click.prevent="setupLocations"
            class="w-100 submit-form-btn"
            :disabled="loading || !selectedLocations.length"
        >
            <template v-if="loading">
                <b-spinner small class="mr-50"/>
                <span class="align-middle">Loading</span>
            </template>
            <span v-else>Set Locations</span>
        </b-button>
    </div>
</template>

<script>
import {BFormInput, BButton, BSpinner} from "bootstrap-vue";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import LocationsService from "@/services/LocationsService";
import SelectLocationsBox from "@/layouts/components/auth/SelectLocationsBox";
import toastsMixin from "@/mixins/toastsMixin";

export default {
    name: 'SetupLocations',
    components: {
        BFormInput,
        BButton,
        BSpinner,
        AuthCardLeft,
        SelectLocationsBox
    },
    mixins: [toastsMixin],
    data() {
        return {
            loading: false,
            locationsLoading: true,
            locations: [],
            displayedLocations: [],
            locationsSearch: '',
        }
    },
    mounted() {
        this.fetchLocations();
    },
    computed: {
        selectedLocations() {
            return this.locations.filter(location => location.selected);
        }
    },
    watch: {
        locationsSearch() {
            this.displayedLocations = this.locations.filter(location => {
                return location.name.toLowerCase().includes(this.locationsSearch.toLowerCase());
            });
        }
    },
    methods: {
        async fetchLocations() {
            this.locationsLoading = true;
            const { data: { locations } } = await LocationsService.get.locations()
            this.locations = locations.map(location => ({ ...location, selected: false }));
            this.displayedLocations = this.locations;
            this.locationsLoading = false;
        },
        selectLocation(location) {
            location.selected = !location.selected;

            const compareLocations = (a, b) => {
                if (a.selected > b.selected) {
                    return -1;
                }
                if (a.selected < b.selected) {
                    return 1;
                }
                return 0
            };

            this.locations.sort(compareLocations);
            this.displayedLocations.sort(compareLocations);
        },
        async setupLocations() {
            try {
                this.loading = true;
                const selectedLocationIds = this.selectedLocations.map(location => location.id);

                const {
                    data: {
                        estimated_time_mins,
                        steps
                    }
                } = await LocationsService.post.saveLocations(selectedLocationIds);

                await this.$store.dispatch('auth/authorizeFromLocalJWT');
                this.loading = false;
                this.showSuccessToast('Locations setup successfully!');
                return this.$router.push({ name: 'auth.setup-account', params: { time: estimated_time_mins } });
            } catch (err) {
                console.log(err);
                this.loading = false;
                this.showErrorToast('Something went wrong.');
            }
        }
    }
}
</script>
